<template>
    <v-container> 

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-forum</v-icon>
            Meeting Room Booking
        </v-card-title>

        <!-- Looping List Tab -->
        <v-tabs
        v-model="tabs"
        show-arrows="mobile"
        >
            <v-tab v-for="item in tabsMeetingRoom" :key="item.tab" class="tab-icon">
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.tabName }}
            </v-tab>
        </v-tabs>

        <!-- Container Tabs -->
        <v-tabs-items v-model="tabs">

            <!-- Tab Inbox -->
            <v-tab-item class="container-tab-modul">
                
                <v-container>
                    <inbox-datatable></inbox-datatable>
                </v-container>
                
            </v-tab-item>

            <!-- Tab Calendar -->
            <v-tab-item class="container-tab-modul">
                
                <calendar-calendar></calendar-calendar>
                
            </v-tab-item>



            <!-- Tab Meeting Room Info -->
            <v-tab-item class="container-tab-modul">
                
                <car-info></car-info>

            </v-tab-item>

        </v-tabs-items>



    </v-container>
</template>

<script>

import dtMeetingRoom from '@/components/meetingRoom/Datatable'
import calMeetingRoom from '@/components/meetingRoom/Calendar'
import infoMeetingRoom from '@/components/meetingRoom/RoomInfo'
export default {
    components: {
        'inbox-datatable': dtMeetingRoom,
        'calendar-calendar': calMeetingRoom,
        'car-info': infoMeetingRoom,
    },
    data: () => ({

        tabs: null,
        tabsMeetingRoom: [
            {tabName: "Inbox", icon: "mdi-inbox-multiple"},
            {tabName: "Book Room", icon: "mdi-calendar-multiselect"},
            {tabName: "Info", icon: "mdi-information-outline"},
        ],


    }),
    methods: {
    
    },
    mounted(){
        // console.log(this.$store);
    }
    
}


</script>

<style lang="scss">
@import '~scss/main';

// template default


</style>
