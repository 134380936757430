<template>
    <v-container>

        <v-card :class="$vuetify.breakpoint.mdAndDown ? '' : 'main-inforoom-card'">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="9">

                        <v-card>

                            <v-img class="pa-5" width="auto" :src="displayRoomSelected.src">

                            </v-img>

                        </v-card>

                        <v-container>
                            <v-row>
                                <v-col>
                                    <strong class="mr-2">
                                        <v-icon dense>mdi-chair-rolling</v-icon>
                                        Room:
                                    </strong>
                                    <span v-html="displayRoomSelected.name"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong class="mr-2">
                                        <v-icon dense>mdi-map-marker-radius</v-icon>
                                        Location:
                                    </strong>
                                    <span v-html="displayRoomSelected.location"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong class="mr-2">
                                        <v-icon dense>mdi-projector-screen-outline</v-icon>
                                        Facilities:
                                    </strong>
                                    <span v-html="displayRoomSelected.facilities"></span>
                                </v-col>
                            </v-row>
                        </v-container>
                        
                    </v-col>
                    <v-col cols="12" sm="3">

                        <v-card :style="$vuetify.breakpoint.mdAndDown ? 'height: calc(100vh - 200px);' : 'height: calc(100vh - 255px);'">

                            <v-card-text
                            style="font-weight:bold;"
                            v-if="$vuetify.breakpoint.mdAndDown"
                            >
                            Scrolldown and select to view the room information. <v-icon small>mdi-arrow-down-thick</v-icon>
                            </v-card-text>

                            <v-form>

                                <v-container :style="$vuetify.breakpoint.mdAndDown ? 'height: calc(100vh - 290px);' : 'height: calc(100vh - 255px);'" class="scroll">
                                    <div
                                    height="400px"
                                    class="list-oneroom my-2 pa-3"
                                    v-for="item in listRoom"
                                    :key="item.value"
                                    @click="displayDetailsRoom(item)"
                                    >
                                
                                        <v-img height="auto" width="300" :src="item.src"> 
                                        </v-img>

                                        <span v-html="item.name"></span>
                                    
                                    </div>
                                </v-container>

                            </v-form>

                        </v-card>

                    </v-col>
                </v-row>
            </v-container>
        </v-card>

    </v-container>
</template>

<script>

export default {
    data: () => ({

        // Tab Info Meeting Room
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],

        listRoom: [
            {
                src: require('@/assets/img/meetingRooms/MNJ1.jpg'),
                name: "MNJ 01 Meeting Room",
                location: "T.01 - U.06 (Largest meeting room)",
                facilities: 'Projector'
            },
            {
                src: require('@/assets/img/meetingRooms/MNJD.jpg'),
                name: "MNJ 02 Discussion Room",
                location: "T.01 - U.06 (Next to MNJ 01 Meeting Room)",
                facilities: '-'
            },
            {
                src: require('@/assets/img/meetingRooms/SPW1.jpg'),
                name: "SPW 01 Meeting Room",
                location: "T.02 - U.04 (Next to pantry)",
                facilities: 'TV'
            },
            {
                src: require('@/assets/img/meetingRooms/SPW2.jpg'),
                name: "SPW 02 Meeting Room",
                location: "T.02 - U.03 (Same lot as boss)",
                facilities: 'TV'
            },
            {
                src: require('@/assets/img/meetingRooms/SPW3.jpg'),
                name: "SCT 01 Meeting Room",
                location: "T.02 - U.07",
                facilities: 'TV'
            },
            {
                src: require('@/assets/img/meetingRooms/SIS1.jpg'),
                name: "SIS 01 Meeting Room",
                location: "T.02 - U.08 (Serasi office)",
                facilities: 'TV'
            },
        ],

        displayRoomSelected: {
            src: require('@/assets/img/meetingRooms/MNJ1.jpg'),
            name: 'MNJ 01 Meeting Room',
            location: 'T.01 - U.06 (Largest meeting room)',
            facilities: 'Projector'
        },

    }),
    methods: {

        displayDetailsRoom(data){
            // console.log(data);

            this.displayRoomSelected.src = data.src;
            this.displayRoomSelected.name = data.name;
            this.displayRoomSelected.location = data.location;
            this.displayRoomSelected.facilities = data.facilities;
        }

    },
    mounted(){
        // console.log(this.$store);
    }
}

</script>

<style lang="scss">
@import '~scss/main';

.main-inforoom-card{
    height: calc(100vh - 230px);
}

.list-oneroom{
    border-bottom: 0.5px solid #dcdcdc;
    cursor: pointer;
}

.list-oneroom:hover{
    background: #dcdcdc;
}

.scroll {
    overflow-y: scroll;
    height: 400px;
    padding: 20px;
}

</style>
