<template>
    <v-container>

        <!-- Detail information -->
        <v-card>
            <v-card-text>
                <ul class="bullet-icon">

                    <li>
                    <v-icon small>mdi-chat-remove</v-icon>
                    To cancel the booking, click on cancel room icon on table list. <span class="text-bold">Please noted, cancel booking button will disabled once event start. Please cancel before event.</span> 
                    </li>

                    <li>
                    <v-icon small>mdi-help-circle</v-icon>
                    For any inquiries regarding the room booking application or cancellation, please contact: <br>
                    <div class="ml-5">
                        <v-icon small class="mr-2">mdi-account</v-icon> NOR INTAN SHUHAILI BINTI ISMAIL <br>
                        <v-icon small class="mr-2">mdi-phone</v-icon> 0133942532 
                    </div>
                    </li>
                </ul>
            </v-card-text>
        </v-card>

        <!-- Details Table -->
        <v-card class="mt-5 darkTable">
            <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="searchDtMeetingRoom"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                outlined
                dense
                class="shrink"
            ></v-text-field>
            </v-card-title>
            <v-data-table
            id="dtRoomBook"
            class="dtRoomBook"
            :items-per-page="5"
            :headers="headerMeetingRoom"
            :items="filteredData"
            :search="searchDtMeetingRoom"
            :loading="loadingDataTable"
            :loading-text="loadingText"
            :custom-sort="customSort"
            >
                <template v-for="(header, i)  in headerMeetingRoom" v-slot:[`header.${header.value}`]="{  }"> 
                    <span @click.stop :key="i">
                        <v-text-field :key="i"
                        v-model="searchObj[header.value]"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        style="font-weight: 100;"
                        :disabled="isDisable(i)"
                        ></v-text-field>
                        {{ header.text }}
                    </span>
                </template>
                    
                <template v-slot:[`item.assistancestatus`]="{ item }">
                    
                    <!-- <v-icon
                    v-if="item.assistancestatus == 'YES'"
                    class="success--text"
                    >
                        mdi-check-all
                    </v-icon> -->
                    <v-chip
                    v-if="item.assistancestatus == 'YES'"
                    color="blue"
                    class="text-bold white--text"
                    >
                    REQUIRED
                    </v-chip>
                    
                    <!-- <span
                    v-if="item.assistancestatus == 'NO' || item.assistancestatus == '' || item.assistancestatus == null"
                    class="text-bold"
                    >
                        N/A
                    </span> -->
                    <v-chip
                    v-if="item.assistancestatus == 'NO' || item.assistancestatus == '' || item.assistancestatus == null"
                    class="text-bold"
                    >
                    N/A
                    </v-chip>

                </template>
                    
                <template v-slot:[`item.refreshmentsstatus`]="{ item }">
                    <v-chip
                    :class="controlColorFont(item.refreshmentsstatus)"
                    :color="controlBgColor(item.refreshmentsstatus)"
                    >
                    {{item.refreshmentsstatus}}
                    </v-chip>
                </template>

                <template v-slot:[`item.detailsAll`]="{ item }">
                    <v-icon
                    color="primary"
                    dense
                    v-html="item.detailsAll"
                    @click="openFileDoc(item)"
                    >
                    </v-icon>
                </template>

                <template v-slot:[`item.cancelRoom`]="{ item }">
                    
                    <span
                    v-if="Date.parse(new Date(item.start)) - Date.parse(new Date()) > 0"
                    >
                       <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                color="red"
                                dense
                                v-html="item.cancelRoom"
                                @click="confirmationDelete(item)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                </v-icon>
                            </template>
                            <span>Cancel Room Booking</span>
                        </v-tooltip> 
                    </span>

                    <span
                    v-if="Date.parse(new Date(item.start)) - Date.parse(new Date()) < 0"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                dense
                                v-html="item.cancelRoom"
                                v-bind="attrs"
                                v-on="on"
                                >
                                </v-icon>
                            </template>
                            <span>The event has already started/passed.</span>
                        </v-tooltip>
                    </span>
                    

                </template>

                

            </v-data-table>
        </v-card>



        <!-- Popup Details -->
        <template>
            <v-dialog
            v-model="dialogDisplayDetails"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Meeting Room Details</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <tr
                        v-for="item in displayDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>
                            
                            <td v-if="item.value != 'PENDING' && item.value != 'APPROVED' && item.value != 'REJECTED'">{{ item.value }}</td>
                            <td v-if="item.value == 'PENDING' || item.value == 'APPROVED' || item.value == 'REJECTED'">
                                <v-chip
                                dense
                                :class="controlColorFont(item.value)"
                                :color="controlBgColor(item.value)"
                                >
                                {{ item.value }}
                                </v-chip>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Cancel Booking Room -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="warningDelete"
                persistent
                max-width="300"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-delete-empty</v-icon>
                        Cancel Booking?
                    </v-card-title>
                    <v-card-text class="blackk--text">Are you sure want to cancel this booking?</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="warningDelete = false;"
                    >
                        NO
                    </v-btn>
                    <v-btn
                        class="text-bold red--text"
                        text
                        @click="warningDelete = false; cancelBooking();"
                    >
                        YES
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Dialog Success -->
        <template>
            <v-dialog
            v-model="dialogSuccessRoomBook"
            persistent
            max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5 success--text">
                    <v-icon class="icon-text success--text">mdi-check-circle</v-icon>
                    Success!
                </v-card-title>
                <v-card-text class="blackk--text">{{textSuccess}}</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="text-bold primary--text"
                    text
                    @click="dialogSuccessRoomBook = false; loadDataTableRoom();"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>

        <!-- Error -->
        <template>
            <v-dialog
            v-model="dialogErrorRoomBooking"
            persistent
            max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5 red--text">
                    <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                    Failed!
                </v-card-title>
                <v-card-text class="blackk--text">Something went wrong. Please try again.</v-card-text>
                <br>
                <v-card-text>
                    <small class="primary--text">
                        <span class="red--text text-bold">**</span> If the error still occurs, please contact HR.
                    </small> 
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="text-bold primary--text"
                    text
                    @click="dialogErrorRoomBooking = false"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>

    </v-container>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default({
    data: () => ({

        loadingDataTable: true,
        loadingText: "", //Data is Loading... Please wait
        textSuccess: "",
        dialogSuccessRoomBook: false,
        dialogErrorRoomBooking: false,


        // Data Table
        myDataBooking: [],

        searchDtMeetingRoom: '',
        headers: [],
        allData: [],
        searchObj: {},
        multiSearch: [],
        headerMeetingRoom: [
            // {text: "Date Applied", value: "dtApplied"},
            {text: "Room Type", value: "name"},
            {text: "Event Title", value: "event"},
            {text: "Start Date Time", value: "startDateTime"},
            {text: "End Date Time", value: "endDateTime"},
            {text: "Tech Assist", value: "assistancestatus"},
            {text: "Refreshment Status", value: "refreshmentsstatus"},
            {text: "Cancellation ", value: "cancelRoom", sortable: false},
            {text: "Detail", value: "detailsAll", sortable: false},
        ],
        bodyMeetingRoom: [
            // {
            //     dtApplied: "2022-01-11 T00:00:00",  
            //     roomType: "MNJ 01 Meeting Room",
            //     eventTitle: "JUPEM Tender Briefing",  
            //     startDt: "2022-01-11 T00:00:00",  
            //     endDt: "2022-01-11 T00:00:00",  
            //     techSupport: "N/A",  
            //     refresh: "PENDING",  
            //     details: "mdi-file-document",  
            // },
            // {
            //     dtApplied: "2022-01-11 T00:00:00",  
            //     roomType: "SIS 01 Meeting Room ",
            //     eventTitle: "JPS Tender Briefing",  
            //     startDt: "2022-01-11 T00:00:00",  
            //     endDt: "2022-01-11 T00:00:00",  
            //     techSupport: "N/A",  
            //     refresh: "APPROVED",  
            //     details: "mdi-file-document",   
            // },
            // {
            //     dtApplied: "2022-01-11 T00:00:00",  
            //     roomType: "MNJ 01 Meeting Room ",
            //     eventTitle: "EQMP Progress Status",  
            //     startDt: "2022-01-11 T00:00:00",  
            //     endDt: "2022-01-11 T00:00:00",  
            //     techSupport: "N/A",  
            //     refresh: "REJECTED",  
            //     details: "mdi-file-document",   
            // },
        ],

        // Display Details
        displayDetails: [
            {key: "Event Title", attribute: "event", value: "", icon: "mdi-pin-outline"},    
            {key: "Event Description", attribute: "description", value: "", icon: "mdi-calendar-text-outline"},    
            {key: "Date Applied", attribute: "dateapplied", value: "", icon: "mdi-calendar-cursor"},    
            {key: "Start Date", attribute: "start", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date", attribute: "end", value: "", icon: "mdi-calendar-end"},    
            {key: "Meeting Room", attribute: "name", value: "", icon: "mdi-chair-rolling"},    
            {key: "Event Pax", attribute: "eventpax", value: "", icon: "mdi-account-group"},    
            {key: "Assist Requirement", attribute: "Assistanceremarks", value: "-", icon: "mdi-handshake-outline"},    
            {key: "Refreshment Requirement", attribute: "refreshmentsremarks", value: "-", icon: "mdi-coffee"},
            {key: "Refreshment Status", attribute: "refreshmentsstatus", value: "", icon: "mdi-check-decagram-outline"},
        ],
        dialogDisplayDetails: false,

        // Cancel Room Booking
        warningDelete: false,
        dataToDelete: null,
        listRoom: [
            {id: 1, label: "MNJ 01 Meeting Room", colorCheckbox: "primary", calendarColor: "red"},
            {id: 2, label: "MNJ 02 Discussion Room", colorCheckbox: "primary", calendarColor: "blue"},
            {id: 3, label: "SPW 01 Meeting Room", colorCheckbox: "primary", calendarColor: "green"},
            {id: 4, label: "SPW 02 Meeting Room", colorCheckbox: "primary", calendarColor: "purple"},
            {id: 5, label: "SCT 01 Meeting Room", colorCheckbox: "primary", calendarColor: "orange"},
            {id: 6, label: "SIS 01 Meeting Room", colorCheckbox: "primary", calendarColor: "grey"},
        ],


    }),
    methods: {

        // Data Table Section
        loadDataTableRoom(){

            this.loadingDataTable = true;
            this.loadingText = "Data is Loading... Please wait";
            this.myDataBooking = [];
            this.allData = [];
            this.headers = [];
            
            this.headers = this.headerMeetingRoom;

            axios.get(process.env.VUE_APP_API_URL + 'meetingroom/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                // console.log(response.data);
                let data = response.data;
                // console.log(data); 
                for(let i in data){
                    data[i]["dtApplied"] = this.convertDateTime(data[i]["dateapplied"]);
                    data[i]["startDateTime"] = this.convertDateTime(data[i]["start"]);
                    data[i]["endDateTime"] = this.convertDateTime(data[i]["end"]);
                    data[i]["detailsAll"] = "mdi-file-document";
                    data[i]["cancelRoom"] = "mdi-chat-remove";
                    if (data[i]["assistancestatus"] == null || data[i]["assistancestatus"] == "") {
                        data[i]["assistancestatus"] = "NO";
                    }
                    if (data[i].idemp == this.$store.getters.user.idEmp) {
                        if(data[i].bookingstatus != "CANCELLED"){
                            this.myDataBooking.push(data[i]);
                        }
                    }
                }

                // this.allData = []
                // this.allData = data;

                // Filter data all - just get data personal current user
                // console.log(this.myDataBooking);
                this.allData = this.myDataBooking;
                this.loadingDataTable = false;
                this.loadingText = "";

                
            })
            .catch(error => {
                console.log(error.response);
                this.loadingDataTable = false;
                this.loadingText = "";
            })
        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);

            items.sort((a, b) => {
                if (index[0]=='dtApplied' || index[0]=='startDateTime' || index[0]=='endDateTime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },

        controlColorFont(status){
            if (status == "APPROVED") {
                return "white--text text-bold" 
            }
            if (status == "REJECTED") {
                return "white--text text-bold" 
            }
            else if(status == "PENDING"){
                return "white--text text-bold" 
            }
            else if(status == "N/A"){
                return "blackk--text text-bold" 
            }
        },

        controlBgColor(status){
            if (status == "APPROVED") {
                return "approve" 
            }
            if (status == "REJECTED") {
                return "reject" 
            }
            else if(status == "PENDING"){
                return "pending" 
            }
            else if(status == "N/A"){
                // return "white" 
            }
        },

        isDisable(index) {
            // console.log(index);
            if (index == 6 || index == 7) {
                return true;
            }
            else{
                return false;
            }
        },

        openFileDoc(data){
            // console.log(data);

            let keyData = Object.keys(data)
            for(let i in this.displayDetails){

                for(let x in keyData){
                    if (this.displayDetails[i].attribute == keyData[x]) {
                        
                        if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
                            this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
                        }
                        else if (keyData[x] == "description") {
                            if (data.description == "" || data.description == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "userContact") {
                            if (data.userContact == "" || data.userContact == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "assistancestatus") {
                            if (data.assistancestatus == "YES") {
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                            else if (data.assistancestatus == "NO") {
                                this.displayDetails[i].value = "N/A";
                                this.displayDetails[i]
                            }
                        }
                        else if (keyData[x] == "Assistanceremarks") {
                            if (data.Assistanceremarks == "" || data.Assistanceremarks == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "refreshmentsremarks") {
                            if (data.refreshmentsremarks == ""  || data.refreshmentsremarks == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "idmeetingroom") {
                            for(let y in this.listRoom){
                                if (data.idmeetingroom == this.listRoom[y].id) {
                                    this.displayDetails[i].value = this.listRoom[y].label;
                                }
                            }
                        }
                        else{
                            this.displayDetails[i].value = data[keyData[x]];
                        }
                    }
                }
            }

            this.dialogDisplayDetails = true;
        },

        confirmationDelete(data){

            this.dataToDelete = data
            this.warningDelete = true;
  
        },

        cancelBooking(){

            // console.log(this.dataToDelete);

            let payloadEdit = {
                idemp: this.dataToDelete.idemp,
                idmeetingroomtype: this.dataToDelete.idmeetingroom,
                event: this.dataToDelete.event,
                description: this.dataToDelete.description,
                startdatetime: this.dataToDelete.start,
                enddatetime: this.dataToDelete.end,
                eventpax: this.dataToDelete.eventpax,
                assistanceremarks: this.dataToDelete.Assistanceremarks,
                refreshmentsremarks: this.dataToDelete.refreshmentsremarks,
                assistanceStatus: "NO",
                refreshmentsstatus: "N/A",
                bookingstatus: "CANCELLED",
            }

            let jsonString = JSON.stringify(payloadEdit);
            // console.log(jsonString);
            // console.log(payloadEdit);

            axios.put(process.env.VUE_APP_API_URL + 'meetingroom/editbook',jsonString,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.textSuccess = "Meeting room booking is succesfully cancelled in system.";
                this.dialogSuccessRoomBook = true;
                this.loadDataTableRoom();
                bus.$emit('changeIt', 'loadDataCalendar');
            })
            .catch(e => {
                console.log(e.response);
                this.dialogSuccessRoomBook = false;
                this.dialogErrorRoomBooking = true;
                // window.loadDt = false;
            })

            // Uncomment code below kalau nk pakai API delete list meeting room ====
            // axios.get(process.env.VUE_APP_API_URL + 'meetingroom/cancelbook?idmeetingroomtype='+this.dataToDelete.idmeetingroom+'&startdate='+this.dataToDelete.start+'&enddate='+this.dataToDelete.end,
            // {
            //     headers: {
            //     "Authorization": 'Bearer ' + this.$store.getters.user.token,
            //     }
            // })
            // .then(response => { //eslint-disable-line
            //     // console.log(response);
            //     this.textSuccess = "Meeting room booking is succesfully deleted in system.";
            //     this.dialogSuccessRoomBook = true;
            //     bus.$emit('changeIt', 'loadDataCalendar');
            // })
            // .catch(e => {
            //     console.log(e.response);
            //     this.dialogSuccessRoomBook = false;
            //     this.dialogErrorRoomBooking = true;
            //     // window.loadDt = false;
            // })

        },

    },
    mounted(){
        this.loadDataTableRoom();
    },
    computed:{
        filteredData(){
            if (this.searchObj) {
                return this.allData.filter(item => {
                    return Object.entries(this.searchObj).every(([key, value]) => {
                        return (item[key] ||'').toLowerCase().includes(value.toLowerCase())
                    })
                })
            }
            else{
                return this.allData
            } 
        },
    },
    created (){
        bus.$on('changeIt', (data) => {

            if (data == "loadNotify") {
                this.loadDataTableRoom();
            }

        })
    }
})
</script>

<style lang="scss">
@import '~scss/main';

// .dtRoomBook > .v-data-table__wrapper{
//     max-height: calc(100vh - 365px); 
// } Sebab laptop Kak Mi hancur viewer (over max height)

</style>
